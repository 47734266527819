.app {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.henlo {
  font-size: 3em;
  color: white;
}