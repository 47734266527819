body {
  font-family: 'Averia Serif Libre', serif;
  font-family: Rubik, sans-serif;
  font-family: Thasadith, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: url('./assets/images/galaxy-3608029.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}